<template>
  <div class="banner-image" v-if="dataFetched">
    <div>
      <div class="flex justify-center">
        <div
          id="video-element"
          class="m-4 md:m-0 md:w-2/3 mt-10"
          v-if="videoLink"
        >
          <p class="text-black text-xl py-4">
            Hi <strong>{{ name }}</strong
            >, Welcome to Texperia
          </p>
          <video
            id="video"
            controls
            preload="metadata"
            class="rounded-lg md:rounded-none"
            playsinline
            poster="https://texperia-testing.s3.ap-south-1.amazonaws.com/events/shrm/poster.png"
          >
            <source :src="videoLink + '#t=0'" type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
          <a
            class="no-underline outline-none focus:outline-none"
            href="https://calendly.com/texperia/demo"
            target="_blank"
          >
            <div
              class="bg-blue-600 w-full py-4 text-white text-center uppercase text-xl font-medium mt-4 rounded-lg"
            >
              Click here to schedule a demo
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  name: "SHRMEvent",
  data() {
    return {
      dataFetched: false,
      videoLink: null,
      name: null,
      experience_link_id: this.$route.params.experience_link_id,
    };
  },
  created() {
    const vue = this;
    ApiService.get(apiResource.events.getSHRMExpDetails, {
      experience_link_id: this.experience_link_id,
    })
      .then(({ data }) => {
        vue.dataFetched = true;
        vue.name = data.data.name;
        vue.videoLink = data.data.link;
      })
      .catch(() => {});
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.calendlyBg {
  background: #006bfc;
}
</style>
